<template>
  <div v-if="isActive">

    <div v-if="isDebug">
      Debug captcha
    </div>

    <recaptcha v-else v-model="captcha" :invalid="true" />

  </div>
</template>

<script>
import Recaptcha from "@/views/components/Recaptcha";

export default {
  components: {Recaptcha},

  props: {
    value: Boolean,

    isActive: {
      type: Boolean,
      default: true
    },

    isDebug: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      captcha: this.value,
    }
  },

  watch: {
    captcha(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.captcha = val
    },
  }
}
</script>