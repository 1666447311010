<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary"></v-progress-linear>
        <v-card-text class="text-center">
          <v-avatar size="60" class="mb-4">
            <img src="@/assets/images/logo.svg" alt="" />
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("auth.account_unblocking") }}
          </h6>

          <ca-alert v-if="alert.message" :type="alert.type">{{ alert.message }}</ca-alert>

          <v-form ref="form">
            <v-text-field
              v-model="email"
              :label="$t('common.email')"
              :rules="emailRules"
              required
              :error-messages="fieldsErrors.email"
            />

            <captcha v-model="captcha" :is-debug="debugCaptcha" />

            <v-btn class="mb-4" block color="primary" dark @click="formSubmit">
              <v-icon left>mdi-lock-reset</v-icon>
              {{ $t("auth.unblock") }}
            </v-btn>

            <div class="d-flex justify-around flex-wrap">
              <v-btn text small color="primary" class="mb-2" :to="{ name: 'login' }">{{ $t("auth.sign_in") }}</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import Captcha from "@/views/components/Captcha.vue";
import CaAlert from "@/views/components/Alert";

export default {
  components: { CaAlert, Captcha },
  metaInfo() {
    return {
      title: this.$t("titles.restore_access")
    };
  },
  data() {
    return {
      alert: {
        message: null,
        type: null
      },
      show: false,
      email: "",
      debugCaptcha: false,
      captcha: false,
      loading: false,
      emailRules: [v => !!v || this.$t("auth.email_required")],
      fieldsErrors: {
        email: [],
        captcha: null
      },
      captchaErrorMessage: this.$t("auth.enter_captcha")
    };
  },

  mounted() {
    this.loadCaptchaState()
  },

  methods: {
    formSubmit() {
      if (this.loading) {
        return;
      }

      this.alert.message = null;

      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.captcha && !this.debugCaptcha) {
        this.alert.message = this.captchaErrorMessage;
        this.alert.type = "error";
        return;
      }

      this.loading = true;
      this.alert.message = null;
      this.emptyFieldErrors();

      let data = {
        email: this.email
      };

      data["captcha"] = this.debugCaptcha ? '1' : window.grecaptcha.getResponse();

      this.axios
        .post("auth/request-restore-access", data)
        .then(response => {
          this.loading = false;

          const data = response.data;

          if (data.success) {
            this.alert.message = this.$t("auth.account_restore_email_sent");
            this.alert.type = "success";
          } else {
            this.alert.message = this.$t("auth.account_was_blocked");
            this.alert.type = "error";
          }
        })
        .catch(error => {
          this.loading = false;
          this.captcha = false;

          const response = error.response;

          if (response.status === 422) {
            this.fieldsErrors = response.data.errors;

            if (this.fieldsErrors.captcha) {
              this.alert.message = this.captchaErrorMessage;
              this.alert.type = "error";
            }
          } else if (response.status === 429) {
            this.alert.message = response.data.message;
            this.alert.type = "error";
          } else {
            alert("Restoring access failed");
          }
        });
    },

    emptyFieldErrors() {
      this.fieldsErrors = {
        email: [],
        captcha: null
      };
    },

    loadCaptchaState() {
      this.axios
        .get("/captcha/login")
        .then(response => {
          this.debugCaptcha = response.data.debug;
        })
        .catch(() => {
          alert("Some error happened");
        });
    }
  }
};
</script>
